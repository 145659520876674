import { styled } from "@mui/material/styles";

import Alert from '@mui/material/Alert';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

//Page Style
export const CustomAlert = styled(Alert)(({ theme }) => ({
    margin: theme.spacing(1),
}));

export const TypographyOptionLabel = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
}));

export const TypographyHeading = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
}));

export const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export const CustomAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: '#eeeeee',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));

export const GridMarginAuto = styled(Grid)(() => ({
  margin: 'auto',
}));

export const TypographyLastUpdate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
}));

export const TypographyLastUpdate14 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightRegular,
}));
