import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { RootState } from "@/store";

import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';

//Icons
import ShareIcon from '@mui/icons-material/Share';
import {RequestParam, ResponseJson, ShareInfo} from "@/assets/apitype/share";
import * as fetchUtil from "@/util/fetchUtil";
import moment from "moment";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { CustomAlert, TypographyOptionLabel } from '@/util/styled'

//Page Props
type Props = {
  open:boolean,
  setOpen:React.Dispatch<React.SetStateAction<boolean>>,
  callbackOK: (shareInfo:ShareInfo) => void,
  callbackCancel: () => void,
  share:string,
}

//Page Style
export const TypographyOptionValue = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
}));

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#itemList-share-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

//Main
const ShareDialog = (props: Props): JSX.Element => {
  // const router = useRouter();

  //Store, Dispatcher
  const userStore = useSelector((state: RootState) => state.user);
  const [errorMessage, setErrorMessage] = React.useState('');
  const initialShareInfo:ShareInfo = {
    uuid:'',
    userid:'',
    username:'',
    sharedate:null,
    url:'',
    pagename:'',
    option: ''
  };
  const [shareInfo, setShareInfo] = React.useState(initialShareInfo);

  //DEF componentDidMount
  const componentDidMount = ()=>{
    if(props.share) {
      //
      const shareid = props.share;
      const suburl = "/shareget";
      const params:RequestParam = {
        id: shareid,
      };
      // fetch(url)
      fetchUtil.post(suburl, params, userStore)
        .then((response)=>(response.json()))
        .then((data: ResponseJson)=>{
          //処理エラー
          if(data.errorMessage) {
            setErrorMessage(data.errorMessage);
          }
          //処理成功
          else {
            setShareInfo({...initialShareInfo, ...data.data});
          }
        })
        //システムエラー
        .catch((err)=>{
          setErrorMessage(err.message);
        })


    }
  };

  useEffect(() => {
    //DO componentWillUnmount
    componentDidMount.bind(this)();
  }, []); //Empty

  //handler
  //OKボタン
  const handleOK = () =>  {
    props.setOpen(false);
    props.callbackOK(shareInfo);
  }
  const handleCancel = () =>  {
    props.setOpen(false);
    props.callbackCancel();
  }

  return (
    <>
          {/* 確認ダイアログ */}
          <Dialog
            open={props.open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleCancel();
              }
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="itemList-share-dialog-title"
            aria-describedby="itemList-share-dialog-description"
            maxWidth={false}
          >
            <DialogTitle id="itemList-share-dialog-title">
              <Grid container justifyContent="flex-start">
                <Grid item style={{marginRight: "auto"}}>
                  <ShareIcon/>検索結果共有
                </Grid>
              </Grid>

            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container justifyContent="flex-start">
                <Grid item style={{marginRight: "auto"}}>
                  <DialogContentText id="itemList-share-dialog-description">
                    共有された検索結果を表示しますか？
                  </DialogContentText>
                </Grid>
              </Grid>
              {errorMessage ?
                <CustomAlert severity="error">{errorMessage}</CustomAlert>
              : <></>}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <TypographyOptionLabel>共有者</TypographyOptionLabel>
                  <TypographyOptionValue>{shareInfo.username}</TypographyOptionValue>
                </Grid>
                <Grid item>
                  <TypographyOptionLabel>共有日時</TypographyOptionLabel>
                  <TypographyOptionValue>{shareInfo.sharedate ? moment(shareInfo.sharedate).format('YYYY/MM/DD HH:mm:ss') : ''}</TypographyOptionValue>
                </Grid>
                <Grid item>
                  <TypographyOptionLabel>画面名</TypographyOptionLabel>
                  <TypographyOptionValue>{shareInfo.pagename}</TypographyOptionValue>
                </Grid>
              </Grid>


            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{handleOK()}} color="primary" autoFocus disabled={!(!(errorMessage))}>
                OK
              </Button>
              <Button onClick={()=>{handleCancel()}} color="inherit" autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
    </>
  );
};




//Export
export default ShareDialog;
